import React, { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Dropdown } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid } from 'swiper';
import { fetchRequests , updateBuyerWallet } from '../../../api/buyerDashboardApi.js'; // Import the fetchRequests function
import { fetchBuyerData } from '../../../api/buyerApi.js'; // Import the fetchRequests function
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import img from '../../../assets/images/box-item/building.jpg'
import { fileBaseUrl2 } from '../../../api/constants.js';
import Web3 from "web3";

const Liveaution = (props) => {
    const data = props.data;
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [modalShow, setModalShow] = useState(false);
    const [countdownDate] = useState(new Date('7/30/2022').getTime());
    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data
    //wallet connection
    let web3;

    const connectWallet = async () => {
        if (window.ethereum) {
            web3 = new Web3(window.ethereum);
            try {
                await window.ethereum.request({ method: "eth_requestAccounts" });
                const accounts = await web3.eth.getAccounts();
                return accounts[0]; // Return the first account (user wallet)
            } catch (error) {
                console.error("User denied account access:", error);
            }
        } else {
            console.error("Please install Metamask!");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = await fetchRequests(); // Fetch Requests data from backend API
                setRequestsData(requests[0]); // Set fetched Requests data to state

                const buyerEmail = localStorage.getItem('email');
                const buyerData = await fetchBuyerData(buyerEmail);
                if (!buyerData) {
                    // Redirect to /login-partner if partnerData is not available
                    navigate('/register-buyer');
                } else {
                    // Set the first name and last name to localStorage
                    localStorage.setItem('firstName', buyerData.first_name);
                    localStorage.setItem('lastName', buyerData.last_name);
                }
                console.log('buyer data in front:', buyerData)


                const buyerWallet = await connectWallet();
                console.log("buyerWallet:", buyerWallet);
                if (!buyerWallet) {
                    alert("Metamask connection failed!");
                    return;
                }
                const buyer_email = localStorage.getItem("email");
                await updateBuyerWallet(buyer_email, buyerWallet);


            } catch (error) {
                console.error('Error fetching Requests:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);

    console.log("requestsData:", requestsData);



    return (
        <Fragment>
            <div className="tf-section dark-style flat-auctions live-auctions">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-box explore-1 flex-one mb-100">
                                <div className="seclect-box style-1 flex text-t font">

                                </div>
                                <div className="seclect-box style-2 box-right flex">
                                    <div className="title-item btn-selector nolink fw-6 font fs-16">‌<button style={{ padding: "0.5em", borderColor: "black", width: "10em", height: "4em" }}>Buyer Dashboard </button></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <Swiper
                                className='swiper-container carousel-6 show-shadow btn-collection2'
                                modules={[Navigation, Grid]}
                                spaceBetween={30}
                                navigation
                                grid={{
                                    "rows": 2
                                }}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1200: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {
                                    requestsData.slice(0, 10000).map(item => (
                                        <SwiperSlide key={item.id}>
                                            <div className="sc-card-product sc-card-article blog-article">
                                                <div className="card-media flat-img">
                                                    <Link to={{ pathname: "/liveAuctionDetails", search: `?id=${item.project_id}` }}>
                                                        {item.projectPhoto_link ? (
                                                            <img
                                                                src={`${fileBaseUrl2}/${item.projectPhoto_link.replace(/\\/g, '/')}`}
                                                                alt="Project"
                                                            />) : (
                                                            <img src={img} alt="Project" />
                                                        )}
                                                    </Link>
                                                </div>
                                                <div className="content">
                                                    <h3><Link to={{ pathname: "/liveAuctionDetails", search: `?id=${item.project_id}` }}>
                                                        Project Name: {item.project_name}
                                                    </Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">

                                                            <div className="info">
                                                                <h5> By<Link className="text-color-3" to="/author">{item.owner_first_name} {item.owner_last_name}</Link> </h5>
                                                                <div className="date"> Min Bid <span>{item.minimum_bid} ETH</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="meta-price flex-one">
                                                        <div className="button-place-bid">
                                                            <Link className="sc-button btn-3" to={{ pathname: "/liveAuctionDetails", search: `?id=${item.project_id}` }}><span>Place a Bid </span></Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    ))
                                }
                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment>
    );
};


export default Liveaution;
