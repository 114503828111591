// my-app/src/api/createItemApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchRequests = async (ownerEmail) => {
    try {
        console.log('ownerEmail in API', ownerEmail);
        const response = await axios.get(`${API_URL}/set-auction`, {
            params: {
                ownerEmail: ownerEmail
            }
        });
        console.log('Fetched your requests from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateAuctionDetails = async (projectId, data) => {
    try {
        const response = await axios.put(`${API_URL}/set-auction/update-auction-details`, {
            projectId,
            ...data,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating auction details:', error);
        throw error;
    }
};
  


//photo
export const uploadPhoto = async (projectId, formData) => {
    console.log("projectId in front API:",projectId);
    if (!projectId || !formData) {
        throw new Error("Project ID or form data is missing");
    }

    try {
        const response = await axios.post(
            `${API_URL}/set-auction/upload-photo`, 
            formData, 
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                params: { projectId } // Pass project ID as a query parameter
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error uploading photo:", error);
        throw error;
    }
};



export const fetchPhoto = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/set-auction/projectPhoto`, {
            params: { projectId }
        });
        return response.data.projectPhotoPath; // Extract the file path
    } catch (error) {
        console.error('Error fetching photo:', error);
        throw error;
    }
};


export const deletePhoto = async (email) => {
    try {
        const response = await axios.delete(`${API_URL}/editProfile-owner/delete-photo`, {
            data: { email }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting photo:', error);
        throw error;
    }
};
