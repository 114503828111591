import React, { useState } from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from 'react-router-dom';
// Backend Connection
import { registerPartner } from "../api/auth.js";

// Showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PartnerRegister() {

    const [userData, setUserData] = useState({
        email: '',
        password: '',
    });
    const [rePassword, setRePassword] = useState(''); // State for re-password
    const [isPasswordMatch, setIsPasswordMatch] = useState(false); // State for password match check
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const handleRegister = async () => {
        if (!isPasswordMatch) {
            toast.error("Passwords do not match!");
            return;
        }
        try {
            const response = await registerPartner(userData);
            console.log('Registration successful', response);
            setRegistrationSuccess(true);
            localStorage.setItem('email', userData.email);
            localStorage.setItem('password', userData.password);

            // Show success notification
            toast.success('Registration successful!', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect after a delay
                window.location.href = '/editProfile-partner';
            }, 2500);
        } catch (error) {
            console.error('Registration error', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;
                if (errorMessage === 'User already exists') {
                    toast.error('User with this email already exists!');
                } else if (errorMessage === 'Invalid email format') {
                    toast.error('Please enter a valid email address!');
                } else if (errorMessage === 'Inappropriate password format') {
                    toast.error('Password should have minimum 8 characters!');
                }
            } else {
                toast.error('Registration failed!');
            }
        }
    };

    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
        if (e.target.name === 'password') {
            setIsPasswordMatch(e.target.value === rePassword);
        }
    };

    const handleRePasswordChange = (e) => {
        setRePassword(e.target.value);
        setIsPasswordMatch(e.target.value === userData.password);
    };

    return (
        <div className='wrapper-style' id='wrapper'>
            <Header />
            <ToastContainer />
            <PageTitle title='partner Register' />
            <div className="tf-section flat-login flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-login">
                                <div className="box-login post">
                                    <img src={require('../assets/images/backgroup-secsion/hand.png')} alt="Nufti" className="absolute mark-login" />

                                    <div className="heading-login register">
                                        <h2 className="fw-5">Register At Buildynamics</h2>
                                        <div className="flex" style={{ position: 'relative' }}>
                                            <p>Do you have an account? </p>
                                            <Link to="/login-partner" className="text-p text-color-3 fw-6" style={{ position: 'relative', zIndex: 1 }}>
                                                Login
                                            </Link>
                                        </div>
                                    </div>
                                    <form>
                                        <div className="form-login flat-form flex-one">
                                            <div className="info-login">
                                                <fieldset>
                                                    <p className="title-infor-account">Email</p>
                                                    <input type="email" id="email" className="tb-my-input" name="email" placeholder="info@gmail.com" aria-required="true" required
                                                        onChange={handleInputChange}
                                                    />
                                                </fieldset>
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Password</p>
                                                    <input type="password" name="password" className="tb-my-input" id="password" placeholder="*********************************" aria-required="true" required
                                                        onChange={handleInputChange}
                                                    />
                                                </fieldset>
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Re-Password</p>
                                                    <input type="password" className="tb-my-input" id="re-password" placeholder="*********************************" aria-required="true" required
                                                        onChange={handleRePasswordChange}
                                                    />
                                                    {!isPasswordMatch && rePassword && (
                                                        <p style={{ color: 'red' }}>Passwords do not match</p>
                                                    )}
                                                </fieldset>
                                                <div className="row-form style-1 flex-two">
                                                    <label className="flex align">
                                                        <input type="checkbox" />
                                                        <span className="btn-checkbox flex-two"></span>
                                                        <span className="text-p text-color-7">Remember Me</span>
                                                    </label>
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={handleRegister}
                                                    disabled={!userData.password || !rePassword || !isPasswordMatch}
                                                >
                                                    Register
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer data={dataFooter} />
            <Bottom classection='widget-social-footer' />
        </div>
    );
}

export default PartnerRegister;
