import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchProjectById, submitProposal, fetchSubProjectById, uploadFile } from '../api/projectDetailApi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";
import img1 from '../assets/images/icon/icon1.svg';
import img2 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';

//upload attachment
import { fileBaseUrl } from '../api/constants.js';


//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";


function ProjectDetail(props) {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const [dataContact] = useState(
        [
            {
                img: img1,
                text: 'Contact Phone',
                adress: '+55 (9900) 666',
            },
            {
                img: img2,
                text: 'Contact Mail',
                adress: 'info.nft@gmail.com',
            },
            {
                img: img3,
                text: 'Contact Location',
                adress: '18/2 Pollobi, Australia.',
            }
        ]
    )


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const subProjectId = searchParams.get('id');

    const [project, setProject] = useState(null);
    const [subProject, setSubProject] = useState(null);


    const [partnerCost, setPartnerCost] = useState('');
    const [partnerToken, setPartnerToken] = useState('');
    const [proposalDescription, setProposalDescription] = useState('');
    const [file, setFile] = useState('');

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                // Fetch sub-project data first
                const subProjectData = await fetchSubProjectById(subProjectId);
                setSubProject(subProjectData[0]);

                // Fetch project data using project_id from the sub-project
                if (subProjectData[0]?.project_id) {
                    const projectData = await fetchProjectById(subProjectData[0].project_id);
                    setProject(projectData[0]);
                } else {
                    console.warn('Sub-project does not contain project_id');
                }
            } catch (error) {
                console.error('Error fetching project or sub-project details:', error);
            }
        };

        fetchProjectDetails();
    }, [subProjectId]);


    console.log('project in front:', project);
    console.log('subproject in front:', subProject);

    const [uploadSectionVisible, setUploadSectionVisible] = useState(false);


    //proposal submission

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    //wallet connection
    let web3;

    const connectWallet = async () => {
        if (window.ethereum) {
            web3 = new Web3(window.ethereum);
            try {
                await window.ethereum.request({ method: "eth_requestAccounts" });
                const accounts = await web3.eth.getAccounts();
                return accounts[0]; // Return the first account (user wallet)
            } catch (error) {
                console.error("User denied account access:", error);
            }
        } else {
            console.error("Please install Metamask!");
        }
    };



    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const partnerWallet = await connectWallet();
            console.log("partnerWallet:", partnerWallet);
            if (!partnerWallet) {
                alert("Metamask connection failed!");
                return;
            }
            // Submit the proposal to the backend
            await submitProposal({
                subProjectId,
                ownerEmail: project.owner_email,
                ownerFirstName: project.owner_first_name,
                ownerLastName: project.owner_last_name,
                partnerEmail: localStorage.getItem('email'),
                partnerFirstName: localStorage.getItem('firstName'),
                partnerLastName: localStorage.getItem('lastName'),
                proposalDescription,
                partnerCost,
                partnerToken,
                partnerWallet
            });

            setRequestSuccess(true); // Set the registration success state to true
            setUploadSectionVisible(true);

            // Show success notification
            toast.success('Submission successful!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });



        } catch (error) {
            console.error('Error submitting proposal:', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;
            } else {
                toast.error('Submission failed!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };


    ///plan upload

    const handleFileUpload = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('sub_id', subProjectId);
        const partnerEmail = localStorage.getItem('email');

        try {
            const response = await uploadFile(subProjectId, partnerEmail, formData);
            const planPath = `${fileBaseUrl}/${response.planPath}`;
            setFile(planPath);
            toast.success("File uploaded successfully!");
            setTimeout(() => {
                window.location.href = '/dashboard-partner';
            }, 3000);
        } catch (error) {
            console.error("Error uploading plan:", error);
            toast.error("Error uploading plan.");
        }
    };


    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Project Description' style={{ margin: "0", padding: "0" }} />

            <section className="tf-section tf-map" style={{ marginTop: "0", padding: "0" }}>

                <div className="tf-section flat-explore flat-edit-profile flat-auctions-details " style={{ marginTop: "0" }}>

                    <div className="container" style={{ marginTop: "0" }}>
                        <div className="form-upload-profile post" style={{ marginTop: "0" }}>
                            <form action="#" className="form-profile" style={{ marginTop: "0" }}>

                                <div className="form-infor-profile" style={{ marginTop: "0" }}>
                                    <div className="form-infor flex flat-form" style={{ marginTop: "0" }}>

                                        <div className="info-account" style={{ marginTop: "0" }}>

                                            {project.project_name && (
                                                <fieldset >
                                                    <h3 className="title-infor-account">Project Name:</h3>
                                                    <p className="title-infor-account">{project.project_name}</p>
                                                </fieldset>
                                            )}
                                            {project.project_type && (

                                                <fieldset>
                                                    <h3 className="title-infor-account">Project Type:</h3>
                                                    <p className="title-infor-account">{project.project_type}</p>
                                                </fieldset>
                                            )}
                                            {project.initial_phase && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Current Phase of Project:</h3>
                                                    <p className="title-infor-account">{project.initial_phase}</p>
                                                </fieldset>
                                            )}
                                            {project.land_area && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Land Area:</h3>
                                                    <p className="title-infor-account">{project.land_area}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {project.total_building_area && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Total Building Area:</h3>
                                                    <p className="title-infor-account">{project.total_building_area}</p>
                                                </fieldset>
                                            )}
                                            {project.floor_area && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Floor Area:</h3>
                                                    <p className="title-infor-account">{project.floor_area}</p>
                                                </fieldset>
                                            )}
                                            {project.number_of_floor && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Number of Floors:</h3>
                                                    <p className="title-infor-account">{project.number_of_floor}</p>
                                                </fieldset>
                                            )}
                                            {project.units_per_floor && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Units Per Floor:</h3>
                                                    <p className="title-infor-account">{project.units_per_floor}</p>

                                                </fieldset>
                                            )}
                                            {project.total_units && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Total Units:</h3>
                                                    <p className="title-infor-account">{project.total_units}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {project.country && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Country:</h3>
                                                    <p className="title-infor-account">{project.country} EUR</p>
                                                </fieldset>
                                            )}
                                            {project.province && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Province:</h3>
                                                    <p className="title-infor-account">{project.province}</p>
                                                </fieldset>
                                            )}
                                            {project.city && (
                                                <fieldset >
                                                    <h3 className="title-infor-account">city:</h3>
                                                    <p className="title-infor-account">{project.city}</p>
                                                </fieldset>
                                            )}
                                            {project.neighborhood && (
                                                <fieldset >
                                                    <h3 className="title-infor-account">Neighborhood:</h3>
                                                    <p className="title-infor-account">{project.neighborhood}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-infor flex flat-form">
                                        <div className="info-account">

                                            {project.zip_code && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Zip Code:</h3>
                                                    <p className="title-infor-account">{project.zip_code}</p>
                                                </fieldset>
                                            )}
                                            {project.plot_number && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Plot Number:</h3>
                                                    <p className="title-infor-account">{project.plot_number}</p>
                                                </fieldset>
                                            )}
                                            {project.address && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Address:</h3>
                                                    <p className="title-infor-account">{project.address}</p>
                                                </fieldset>
                                            )}
                                            {project.map_link && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Map Link:</h3>
                                                    <p className="title-infor-account">{project.map_link}</p>
                                                </fieldset>
                                            )}
                                            {project.accessibility && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">accessibility:</h3>
                                                    <p className="title-infor-account">{project.accessibility}</p>
                                                </fieldset>
                                            )}
                                            {project.plan_link && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Plan Link:</h3>
                                                    <p className="title-infor-account">{project.plan_link}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            
                                            {subProject.type && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">This Requirement of Project:</h3>
                                                    <p className="title-infor-account">{subProject.tyoe}</p>
                                                </fieldset>
                                            )}
                                            {subProject.expertise && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required Expertise:</h3>
                                                    <p className="title-infor-account">{subProject.expertise}</p>
                                                </fieldset>
                                            )}
                                            {subProject.brand && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required Brand:</h3>
                                                    <p className="title-infor-account">{subProject.brand}</p>

                                                </fieldset>
                                            )}
                                            {subProject.quantity && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required Quantity:</h3>
                                                    <p className="title-infor-account">{subProject.quantity}</p>
                                                </fieldset>
                                            )}
                                            {subProject.startDate && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Delivery or Start Day:</h3>
                                                    <p className="title-infor-account">{subProject.startDate} days after start of project</p>
                                                </fieldset>
                                            )}
                                            {subProject.date && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required Duration:</h3>
                                                    <p className="title-infor-account">{subProject.date} days</p>

                                                </fieldset>
                                            )}
                                        </div>
                                        <div className="info-social">
                                            {subProject.specs && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required Specs:</h3>
                                                    <p className="title-infor-account">{subProject.specs}</p>
                                                </fieldset>
                                            )}
                                            {subProject.unit && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Unit:</h3>
                                                    <p className="title-infor-account">{subProject.unit}</p>
                                                </fieldset>
                                            )}
                                            {subProject.dimensions && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Required Dimentions:</h3>
                                                    <p className="title-infor-account">{subProject.dimensions}</p>
                                                </fieldset>
                                            )}
                                            {subProject.description && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Description of Requirement:</h3>
                                                    <p className="title-infor-account">{subProject.description}</p>
                                                </fieldset>
                                            )}
                                            {subProject.estimatedCost && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Estimated Cost of this requirement:</h3>
                                                    <p className="title-infor-account">{subProject.estimatedCost}</p>
                                                </fieldset>
                                            )}
                                            {subProject.percentage && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">The estimated value in proportion to the total value of the project:</h3>
                                                    <p className="title-infor-account">{subProject.percentage}٪</p>
                                                </fieldset>
                                            )}
                                            {project.tokens && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">Total Tokens of Project:</h3>
                                                    <p className="title-infor-account">{project.tokens}</p>
                                                </fieldset>
                                            )}
                                            {subProject.tokens && (
                                                <fieldset>
                                                    <h3 className="title-infor-account">ٍEstimated number of toknes for this requirement:</h3>
                                                    <p className="title-infor-account">{subProject.tokens}</p>
                                                </fieldset>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div className="back-arrow"
                                style={{ cursor: "pointer", fontSize: "1.5em" }}
                                onClick={() => navigate(-1)}
                            >
                                &#8592; Back
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className=" flat-contact flat-edit-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-contact">
                                <div className="heading center">
                                    <h2 className="fw-8">If you are interested in participating in this project,<br /> Fill Your Proposal</h2>
                                </div>
                                <div className="form-upload-profile post">
                                    <form action="#" className="form-profile" >
                                        <div className="form-infor-profile">
                                            <div className="form-infor flex flat-form">
                                                <div className="info-account">
                                                    <h3> Your final price to meet this need: </h3>

                                                </div>
                                                <div className="info-social">
                                                    <fieldset>
                                                        <input type="number" placeholder="Price (IN EUR)"
                                                            value={partnerCost} onChange={(e) => setPartnerCost(e.target.value)}
                                                            required />
                                                    </fieldset>
                                                </div>
                                                <div className="info-account">
                                                    <h3> The number of tokens you want: </h3>

                                                </div>
                                                <div className="info-social">
                                                    <fieldset>
                                                        <input type="number" placeholder="consider all tokens"
                                                            value={partnerToken} onChange={(e) => setPartnerToken(e.target.value)}
                                                            required />
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <fieldset className="message">
                                                <textarea placeholder="Write Your Proposal" tabIndex="4" rows="3"
                                                    value={proposalDescription} onChange={(e) => setProposalDescription(e.target.value)}
                                                    required></textarea>
                                            </fieldset>

                                        </div>
                                        <div className="button-contact center">

                                            <button className="submit" type="submit" onClick={handleFormSubmit} >
                                                Submit Now
                                            </button>

                                        </div>
                                    </form>
                                    {uploadSectionVisible && (

                                        <>
                                            <h4 className="title-one fw-6" style={{ marginTop: "2em" }}>Here you can upload resume, program, product catalog or...</h4>

                                            <div id="upload-profile" style={{ marginTop: "0" }}>
                                                <label className="btn-upload" style={{ padding: "1em", cursor: "pointer", backgroundColor: "black", maxWidth: "30%", justify: "right", position: "flex" }}>
                                                    Upload Your File
                                                    <input
                                                        id="tf-upload-img"
                                                        type="file"
                                                        onChange={handleFileUpload}
                                                        style={{ display: "none" }}
                                                    />
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default ProjectDetail;