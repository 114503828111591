// my-app/src/api/auth.js
import axios from 'axios';

//---------------------1 changes for deploy
import { API_URL } from './constants';

export const registerOwner = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-owner`, userData);
    console.log("response in api", response.data);
    return response.data;
  } catch (error) {
    console.log('maybe problem in response', error)
    throw error;
  }
};

export const registerPartner = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-partner`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerBuyer = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register-buyer`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};




export const loginOwner = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-owner`, userData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const loginPartner = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-partner`, userData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const loginBuyer = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-buyer`, userData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const loginJury = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login-jury`, userData);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const editOwner = async (userData) => {
  try {
    const response = await axios.put(`${API_URL}/editProfile-owner`, userData);
    console.log("response in api", response.data);
    return response.data;
  } catch (error) {
    console.log('maybe problem in response', error)
    throw error;
  }
};

export const editPartner = async (userData) => {
  try {
    const response = await axios.put(`${API_URL}/editProfile-partner`, userData);
    console.log("response in api", response.data);
    return response.data;
  } catch (error) {
    console.log('maybe problem in response', error)
    throw error;
  }
};