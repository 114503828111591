import axios from 'axios';

import { API_URL } from './constants';

export const fetchOwnerData = async (email) => {
    try {
        const response = await axios.get(`${API_URL}/owner`, {
            params: {
                email: email
            }
        });
        console.log('Fetched owner data from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
