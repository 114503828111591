// my-app/src/api/applicantsApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchAuctions = async (projectId) => {
    try {
        const response = await axios.get(`${API_URL}/bidders?id=${projectId}`);
        console.log('Fetched project applicants from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
