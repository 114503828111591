import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Profile from '../components/layouts/ownerdashboard/dashboard.jsx';
import dataProfile from '../assets/fake-data/data-liveaution.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

import { fetchRequests , fetchSubProjects , uploadPhoto, deletePhoto} from '../api/ownerDashboardApi.js'; // Import the fetchRequests function
import { fetchOwnerData } from '../api/ownerApi.js'; // Import the fetchRequests function

import imgauthor1 from '../assets/images/avatar/avt-01.png';

import icon1 from '../assets/images/icon/author-check.svg';
import icon2 from '../assets/images/icon/icon-diamond.svg';

import img1 from '../assets/images/box-item/auctions-111.jpg';
import img2 from '../assets/images/box-item/auctions-2.jpg';
import img3 from '../assets/images/box-item/auctions-3.jpg';
import img4 from '../assets/images/box-item/auctions-4.jpg';
import img5 from '../assets/images/box-item/auctions-5.jpg';

function OwnerDashboard(props) {
    const [profilePhoto, setProfilePhoto] = useState();

    //Fetch Project Request from Backend
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data
    const [subProjectsData, setSubProjectsData] = useState([]); // State to store fetched Requests data

    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        const fetchData = async () => {
            try {
                const OwnerEmail = localStorage.getItem('email');
                const requests = await fetchRequests(OwnerEmail); // Fetch Requests data from backend API
                setRequestsData(requests[0]); // Set fetched Requests data to state
                const ownerData = await fetchOwnerData(localStorage.getItem('email'));

                const subProjects = await fetchSubProjects(OwnerEmail); // Fetch Requests data from backend API
                setSubProjectsData(subProjects[0]); // Set fetched Requests data to state

                if (!ownerData) {
                    // Redirect to /login-partner if partnerData is not available
                    navigate('/register-owner');
                } else {
                    // Set the first name and last name to localStorage
                    localStorage.setItem('firstName', ownerData.first_name);
                    localStorage.setItem('lastName', ownerData.last_name);
                    setProfilePhoto(ownerData.owner_photo_link)
                }

                // Set the first name and last name to localStorage
                console.log('Owner data in front:', ownerData)
                // localStorage.setItem('firstName', ownerData.first_name);
                // localStorage.setItem('lastName', ownerData.last_name);
            } catch (error) {
                console.error('Error fetching Requests:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);

    const images = [img1, img2, img3, img4, img5];



    // Map the fetched Requests data to match the structure of dataLiveaution
    const mappedRequestsData = requestsData.map((request, index) => ({
        id: `${request.project_id}`,
        img: images[index % images.length], // Use the modulo operator to cycle through the images
       // tags: `${request.num_proposals} Recieved Proposals`,
        //tags_progs: `${request.num_proposals} Recieved Updates`,
        //tags_done1: `${request.num_proposals} Recieved Auctions`,
        imgauthor: imgauthor1,
        title: `${request.project_name}`, // Update with fetched Requests number
        author: `${request.partner_first_name} ${request.partner_last_name}`,
        date: `${request.creation_date}`,
        titleprice: `${(request.budget / 1).toFixed(2)} EUR`, // Update with fetched price
        titleprice_tend: `${(request.budget / 1).toFixed(2)} Coins`, // Update with fetched price
        titleprice_done1: `${(request.budget / 1).toFixed(2)} Coins`, // Update with fetched price
        btn: 'Check Proposals!',
        reviewbtn: 'Reviwe & Edit!',
        btn_pre: 'Check Requirements',
        reviewbtn_pre: 'Reviwe & Edit!',
        btn_progress: 'Check Progress',
        btn_done1: 'Set&Start Auction',
        reviewbtn_done1: 'Check Auctions',
        btn_done2: 'Review Project',
        reviewbtn_done2: 'Delete Project',
        icon1: icon1,
        icon2: icon2,
        titlecountdown: 'Ending Time -',
        status: `${request.project_status}`,
        totalToken: `${request.tokens} Tokens`,
        share:`${(request.owner_share)}`,
    }));

    // Determine the number of rows to display based on the length of the fetched Requests data
    const numRowsToShow = mappedRequestsData.length > 3 ? 3 : mappedRequestsData.length;

    // Slice the mapped Requests data to display only the required number of rows
    const slicedRequestsData = mappedRequestsData.slice(0, numRowsToShow);


    // Combine the mapped Requests data with the remaining dataLiveaution
    const updatedDataLiveaution = [...mappedRequestsData, ...slicedRequestsData.slice(mappedRequestsData.length)];



    // Map subProjectsData
    const mappedSubProjectsData = subProjectsData.map((subProject, index) => ({
        id: `${subProject.sub_id}`,
        img: images[index % images.length],
        title: `${subProject.project_name}`,
        name:  `${subProject.type}`,
        author: `Owner`, // Replace with the relevant field if available
        date: `${subProject.creation_date}`,
        titleprice: `${(subProject.estimatedCost / 1).toFixed(2)} EUR`,
        status: `${subProject.sub_status}`,
        progress: 0, // Use 0 or an appropriate value if progress is not available
        share: `${subProject.tokens} Tokens`,
        partnerShare: `${subProject.partner_token} Tokens`,
        tag: `${subProject.num_proposals}`,
        btn_mgmt: "Manage Requirement",
        btn: `${subProject.num_proposals} Proposals`
    }));


    // Determine the number of rows to display based on the length of the fetched Requests data
    const numSubRowsToShow = mappedSubProjectsData.length > 3 ? 3 : mappedSubProjectsData.length;

    // Slice the mapped Requests data to display only the required number of rows
    const slicedSubRequestsData = mappedSubProjectsData.slice(0, numSubRowsToShow);


    // Combine the mapped Requests data with the remaining dataLiveaution
    const updatedSubDataLiveaution = [...mappedSubProjectsData, ...slicedSubRequestsData.slice(mappedSubProjectsData.length)];



    // Combine data into a single object
    const combinedData = {
        projects: updatedDataLiveaution,
        subProjects: updatedSubDataLiveaution,
    };



    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Dashboard' />
            {<Profile data={combinedData} avatar={profilePhoto} />}
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default OwnerDashboard;
