// frontend/src/api/OwnerProfileApi.js
import axios from 'axios';

import { API_URL } from './constants';

// Function to update CoCampaignResults data
export const updateOwnerProfile = (data, dispatchFirstName, dispatchLastName, dispatchCountry,dispatchCity,
    dispatchRole, dispatchPhone, dispatchAddress, dispatchPhoto) => {
    // Dispatch actions to update the state based on the provided data
    dispatchFirstName(updateFirstNameAction(data));
    dispatchLastName(updateLastNameAction(data));
    dispatchCountry(updateCountryAction(data));
    dispatchCity(updateCityAction(data));
    dispatchRole(updateRoleAction(data));
    dispatchPhone(updatePhoneAction(data));
    dispatchAddress(updateAddressAction(data));
    dispatchPhoto(updatePhotoAction(data))

};



// Example action creators for updating state
export const updateFirstNameAction = (data) => ({
    type: 'UPDATE_FIRST_NAME',
    payload: data[0].first_name, // Check the correct property name
});

export const updateLastNameAction = (data) => ({
    type: 'UPDATE_LAST_NAME',
    payload: data[0].last_name, // Check the correct property name
});


export const updateCountryAction = (data) => ({
    type: 'UPDATE_COUNTRY',
    payload: data[0].country, // Check the correct property name
});

export const updateCityAction = (data) => ({
  type: 'UPDATE_CITY',
  payload: data[0].city, // Check the correct property name
});

export const updateRoleAction = (data) => ({
    type: 'UPDATE_ROLE',
    payload: data[0].role, // Check the correct property name
});

export const updatePhoneAction = (data) => ({
    type: 'UPDATE_PHONE',
    payload: data[0].phone, // Check the correct property name
});


export const updateAddressAction = (data) => ({
    type: 'UPDATE_ADDRESS',
    payload: data[0].address, // Check the correct property name
});

export const updatePhotoAction = (data) => ({
    type: 'UPDATE_OWNER_PHOTO_LINK',
    payload: data[0].owner_photo_link, // Check the correct property name
});


export const fetchOwnerProfile = async (userEmail) => {
    try {
        // Include user email in the request headers
        console.log('userEmail in header', userEmail);
        const headers = { 'user-email': userEmail };

        const response = await axios.get(`${API_URL}/editProfile-owner`, { headers });

        return response.data;
    } catch (error) {
        console.error('Error fetching Owner Profile:', error);
        throw error;
    }
};


export const uploadProfilePhoto = async (email, formData) => {
    try {
      const response = await axios.post(`${API_URL}/editProfile-owner/upload-photo`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading profile photo:', error);
      throw error;
    }
  };
  
  export const fetchProfilePhoto = async (email) => {
    try {
      const response = await axios.get(`${API_URL}/editProfile-owner/photo`, {
        params: { email }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile photo:', error);
      throw error;
    }
  };
  
  export const deleteProfilePhoto = async (email) => {
    try {
      const response = await axios.delete(`${API_URL}/editProfile-owner/delete-photo`, {
        data: { email }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting profile photo:', error);
      throw error;
    }
  };
  

//new get profile data
// Function to get the Owner profile by email
export const getOwnerProfile = async (email) => {
    try {
        // Include user email in the request headers
        console.log('userEmail in header', email);
        const headers = { 'user-email': email };

        const response = await axios.get(`${API_URL}/editProfile-owner`, { headers });

        return response.data;
    } catch (error) {
        console.error('Error fetching owner Profile:', error);
        throw error;
    }
};