// frontend/src/api/PartnerProfileApi.js
import axios from 'axios';

import { API_URL } from './constants';

// Function to update CoCampaignResults data
export const updatePartnerProfile = (data, dispatchFirstName, dispatchLastName, dispatchPhone, dispatchTimeZone, dispatchTags, dispatchPhoto) => {
    // Dispatch actions to update the state based on the provided data
    dispatchFirstName(updateFirstNameAction(data));
    dispatchLastName(updateLastNameAction(data));
    dispatchPhone(updatePhoneAction(data));
    dispatchTimeZone(updateTimeZoneAction(data));
    dispatchTags(updateTagsAction(data));
    dispatchPhoto(updatePhotoAction(data))

};



// Example action creators for updating state
export const updateFirstNameAction = (data) => ({
    type: 'UPDATE_FIRST_NAME',
    payload: data[0].first_name, // Check the correct property name
});

export const updateLastNameAction = (data) => ({
    type: 'UPDATE_LAST_NAME',
    payload: data[0].last_name, // Check the correct property name
});



export const updatePhoneAction = (data) => ({
    type: 'UPDATE_PHONE',
    payload: data[0].phone, // Check the correct property name
});



export const updateTimeZoneAction = (data) => ({
    type: 'UPDATE_TIME_ZONE',
    payload: data[0].time_zone, // Check the correct property name
});

export const updateTagsAction = (data) => ({
    type: 'UPDATE_TAGS',
    payload: data[0].tags, // Check the correct property name
});

export const updatePhotoAction = (data) => ({
    type: 'UPDATE_client_photo_link',
    payload: data[0].client_photo_link, // Check the correct property name
});


export const fetchPartnerProfile = async (userEmail) => {
    try {
        // Include user email in the request headers
        console.log('userEmail in header', userEmail);
        const headers = { 'user-email': userEmail };

        const response = await axios.get(`${API_URL}/editProfile-partner`, { headers });

        return response.data;
    } catch (error) {
        console.error('Error fetching partner Profile:', error);
        throw error;
    }
};
/*
// Function to initialize CoCampaignResults data
export const getإreelancerProfile = async (userEmail, dispatchFirstName, dispatchLastName, dispatchCompanyName,
    dispatchPosition, dispatchCompanyOwner, dispatchPhone, dispatchVatid, dispatchTimeZone, dispatchAddress, dispatchPhoto) => {
    try {
        const initialData = await fetchإreelancerProfile(userEmail);

        console.log('Response from server:', initialData);

        // Call the function to handle the update
        updateإreelancerProfile(initialData, dispatchFirstName, dispatchLastName, dispatchCompanyName,
            dispatchPosition, dispatchCompanyOwner, dispatchPhone, dispatchVatid, dispatchTimeZone, dispatchAddress, dispatchPhoto);
    } catch (error) {
        console.error('Error to get إreelancer Profile:', error);
    }
};
*/
export const uploadProfilePhoto = async (email, formData) => {
    try {
      const response = await axios.post(`${API_URL}/editProfile-partner/upload-photo`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading profile photo:', error);
      throw error;
    }
  };
  
  export const fetchProfilePhoto = async (email) => {
    try {
      const response = await axios.get(`${API_URL}/editProfile-partner/photo`, {
        params: { email }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile photo:', error);
      throw error;
    }
  };
  
  export const deleteProfilePhoto = async (email) => {
    try {
      const response = await axios.delete(`${API_URL}/editProfile-partner/delete-photo`, {
        data: { email }
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting profile photo:', error);
      throw error;
    }
  };
  

//new get profile data
// Function to get the partner profile by email
export const getPartnerProfile = async (email) => {
    try {
        // Include user email in the request headers
        console.log('userEmail in header', email);
        const headers = { 'user-email': email };

        const response = await axios.get(`${API_URL}/editProfile-partner`, { headers });

        return response.data;
    } catch (error) {
        console.error('Error fetching partner Profile:', error);
        throw error;
    }
};