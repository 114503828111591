import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useLocation } from 'react-router-dom';

import '../scss/components/section.scss';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import Liveaution from '../components/liveauctions/liveauction-v3';
import dataLiveaution2 from '../assets/fake-data/data-liveaution';

import CardModal from '../components/CardModal';

import img1 from '../assets/images/avatar/avt-01.png'
import img2 from '../assets/images/avatar/avt-04.jpg'
import img3 from '../assets/images/avatar/avt-05.jpg'
import { fetchProjectById, submitProposal, fetchprojectById, uploadFile, placeBid } from '../api/auctionDetailApi';
import { fileBaseUrl2, platformWalletAddress } from '../api/constants.js';
import img from '../assets/images/box-item/building.jpg'
import Web3 from "web3";


function LiveAuctionDetails(props) {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');

    const [project, setProject] = useState(null);


    const [partnerCost, setPartnerCost] = useState('');
    const [partnerToken, setPartnerToken] = useState('');
    const [proposalDescription, setProposalDescription] = useState('');
    const [file, setFile] = useState('');

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                // Fetch sub-project data first
                const projectData = await fetchProjectById(projectId);
                setProject(projectData[0]);

            } catch (error) {
                console.error('Error fetching project or sub-project details:', error);
            }
        };

        fetchProjectDetails();
    }, [projectId]);


    console.log('project in front:', project);
    console.log('project in front:', project);


    const [modalShow, setModalShow] = useState(false);

    const [bidValue, setBidValue] = useState("");
    const [loading, setLoading] = useState(false);

    const handleBidSubmit = async () => {
        try {
            setLoading(true);

            // Check if MetaMask is installed
            if (!window.ethereum) {
                alert("Please install MetaMask to proceed.");
                return;
            }

            const web3 = new Web3(window.ethereum);
            await window.ethereum.request({ method: "eth_requestAccounts" }); // Request wallet access
            const accounts = await web3.eth.getAccounts();
            const buyerWalletAddress = accounts[0]; // Get the first account

            // Convert bidValue to Wei
            const bidValueInWei = web3.utils.toWei(bidValue, "ether");

            // Send ETH to the platform wallet
            await web3.eth.sendTransaction({
                from: buyerWalletAddress,
                to: platformWalletAddress,
                value: bidValueInWei,
            });

            // Call the backend API to save bid details
            const buyerEmail = localStorage.getItem("email");
            await placeBid({
                projectId,
                bidValue,
                buyerEmail,
                buyerWalletAddress,
            });

            alert("Bid placed successfully!");
        } catch (error) {
            console.error("Error placing bid:", error);
            alert("There was an issue placing your bid. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Live Auctions Details' />
            <Fragment>
                <div className="tf-section flat-auctions-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="wrap-img">
                                    <div className="img-detail">
                                        <div className="media">
                                            {project.projectPhoto_link ? (
                                                <img
                                                    src={`${fileBaseUrl2}/${project.projectPhoto_link.replace(/\\/g, '/')}`}
                                                    alt="Project"
                                                />) : (
                                                <img src={img} alt="Project" />
                                            )}                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="post mtop-7">
                                    <h2 className="fw-5 mt-2">{project.project_name}</h2>
                                    <div className="meta-info mb-17">
                                        <div className="author">

                                            <div className="info">
                                                <h5> Owner<a className="text-color-3" > {project.owner_first_name} {project.owner_last_name} </a> </h5>
                                                <div className="date"> In <span className="text-color-6"> {project.city} - {project.country}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-price flat-price flex">
                                        <div className="price flex">
                                            <div className="title-price"> Minimum Bid:  </div>
                                            <div className='icon-price'>
                                                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.97861 0L0.0426636 9.73665L5.97861 7.13167L12 9.73665L5.97861 0Z" fill="#7759F3" />
                                                    <path d="M5.97865 7.04639L0 9.73678L5.97865 13.1531L12 9.73678L5.97865 7.04639Z" fill="url(#paint0_linear_75_92)" />
                                                    <path d="M5.97872 19.0889L0.0854797 10.8896L5.97872 14.306L11.9147 10.8896L5.97872 19.0889Z" fill="#7759F3" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_75_92" x1="6" y1="7.04639" x2="6" y2="13.1531" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#4D30CA" />
                                                            <stop offset="1" stopColor="#7759F3" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="title-price"> {project.minimum_bid} ETH</div>
                                        </div>
                                    </div>
                                    <div className="information flex">
                                        <div className="column column-1">
                                            <h4 className="title-1 fw-5 text-color-3">Project Type: <span className="">{project.project_type}</span></h4>
                                            <h4 className="fw-5 text-color-3">Land Area: <span className="">{project.land_area} m2</span></h4>
                                        </div>
                                        <div className="column column-2">
                                            <h4 className="title-1 fw-5 text-color-3">Total Buildup Area: <span className="">  {project.total_buildup_area} m2 </span></h4>
                                            <h4 className="fw-5 text-color-3">Floor Area: <span className="">{project.floor_area} m2</span></h4>
                                        </div>
                                    </div>
                                    <div className="featured-countdown">
                                        <div className="flex">
                                            <h3><span className="title-countdown">{project.auction_description} </span>
                                            </h3>
                                            <div className="js-countdown">

                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginTop:"1em", marginBottom:"3em"}}>
                                        <h3 style={{marginTop:"1em", marginBottom:"1em"}}>Place Your Bid</h3>
                                        <input
                                            type="number"
                                            step="0.001"
                                            placeholder="Bid amount (ETH)"
                                            value={bidValue}
                                            onChange={(e) => setBidValue(e.target.value)}
                                            disabled={loading}
                                            style={{width:"11em", marginRight:"2em"}}
                                        />
                                        <button onClick={handleBidSubmit} disabled={loading}
                                         style={{height:"2.5em", width:"11em",borderColor:"black" , padding:"0.5em"}}
                                        >
                                            {loading ? "Processing..." : "Submit Bid"}
                                        </button>
                                       
                                    </div>
                                    <Tabs>
                                        <TabList className="menu-tab tab-title">
                                            <Tab><h3>Project Description</h3></Tab>
                                            <Tab><h3>Address</h3></Tab>
                                            <Tab><h3>More Info</h3></Tab>
                                        </TabList>
                                        <TabPanel>
                                            <div className="form-infor-profile" style={{ marginTop: "0" }}>
                                                <div className="form-infor flex flat-form" style={{ marginTop: "0" }}>
                                                    <div className="info-account" style={{ marginTop: "0" }}>

                                                        {project.project_description && (
                                                            <fieldset >
                                                                <p className="title-infor-account">{project.project_description}</p>
                                                            </fieldset>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="form-infor-profile" style={{ marginTop: "0" }}>
                                                <div className="form-infor flex flat-form" style={{ marginTop: "0" }}>
                                                    <div className="info-account" style={{ marginTop: "0" }}>

                                                        {project.country && (
                                                            <fieldset >
                                                                <p className="title-infor-account">Country: {project.country}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.province && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Province: {project.province}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.city && (
                                                            <fieldset>
                                                                <p className="title-infor-account">City: {project.city}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.accessibility && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Accessibility:   {project.accessibility}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.neighborhood && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Neighborhood:   {project.neighborhood}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.address && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Address:   {project.address}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.map_link && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Map Link:   {project.map_link}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.zip_code && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Zip Code:   {project.zip_code}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.plot_number && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Plot Number:   {project.plot_number}</p>
                                                            </fieldset>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="form-infor-profile" style={{ marginTop: "0" }}>
                                                <div className="form-infor flex flat-form" style={{ marginTop: "0" }}>
                                                    <div className="info-account" style={{ marginTop: "0" }}>

                                                        {project.number_of_floor && (
                                                            <fieldset >
                                                                <p className="title-infor-account">Number of Floor: {project.number_of_floor}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.units_per_floor && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Units per Floor: {project.units_per_floor}</p>
                                                            </fieldset>
                                                        )}
                                                        {project.total_units && (
                                                            <fieldset>
                                                                <p className="title-infor-account">Total Units: {project.total_units}</p>
                                                            </fieldset>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CardModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Fragment>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default LiveAuctionDetails;