import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import Grid2 from "@mui/material/Grid2"; // Use Grid2 for MUI v5+
import { Edit, Delete } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { fetchProjectData, updateProjectData } from "../../../api/requirementsApi.js"; // Import API functions
//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RequirementsProfile = () => {

  const [projectData, setProjectData] = useState({
    initialManpower: [],
    initialMachines: [],
    initialMaterials: [],
  });
  const [manpower, setManpower] = useState([]);
  const [machines, setMachines] = useState([]);
  const [materials, setMaterials] = useState([]);

  const projectId = new URLSearchParams(window.location.search).get('id');

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const data = await fetchProjectData(projectId);
        console.log("fetched data from back", data);
        const { project_name, manpower_requirements, material_requirements, machinary_requirements } = data;
        localStorage.setItem("projectName", project_name);
        console.log("projectName",project_name, localStorage.getItem("projectName"));
        setProjectData({
          initialManpower: manpower_requirements || [],
          initialMachines: machinary_requirements || [],
          initialMaterials: material_requirements || [],
        });
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };
    getProjectData();
  }, [projectId]);

  // Sync other states with projectData
  useEffect(() => {
    setManpower(projectData.initialManpower);
    setMachines(projectData.initialMachines);
    setMaterials(projectData.initialMaterials);
  }, [projectData]);

  console.log('project Data:', projectData);
  console.log('manpower:', manpower);

  const navigate = useNavigate(); // Initialize useNavigate hook
  // Sample initial data
  /*
  const initialManpower = [{ id: 1, type: "Painter", expertise: "Expert", quantity: "1", startDate: "2024-01-01", endDate: "2024-01-20", description: "High priority", estimatedCost: 1100 },{ id: 2, type: "Painter", expertise: "Assistant", quantity: "8", startDate: "2024-01-01", endDate: "2024-01-20", description: "Assist expert painter", estimatedCost: 1100 },];

  const initialMachines = [
    { id: 1, type: "Crane", brand: "ABC", specs: "5 tons", quantity: "2", startDate: "2024-02-01", endDate: "2024-02-20", usageHours: "100", description: "", estimatedCost: 1100 },
  ];

  const initialMaterials = [
    { id: 1, type: "Cement", brand: "XYZ", quantity: "100", unit: "Bags", dimensions: "N/A", deliveryDate: "2024-01-15", description: "", estimatedCost: 1100 },
  ];
*/



  const [newManpower, setNewManpower] = useState({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
  const [newMachine, setNewMachine] = useState({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
  const [newMaterial, setNewMaterial] = useState({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });

  const [editing, setEditing] = useState({ id: null, category: "" });

  const handleInputChange = (e, category) => {
    const { name, value } = e.target;
    switch (category) {
      case "manpower":
        setNewManpower({ ...newManpower, [name]: value });
        break;
      case "machines":
        setNewMachine({ ...newMachine, [name]: value });
        break;
      case "materials":
        setNewMaterial({ ...newMaterial, [name]: value });
        break;
      default:
        break;
    }
  };

  const addEntry = (category) => {
    switch (category) {
      case "manpower":
        setManpower([...manpower, { ...newManpower, id: manpower.length + 1 }]);
        setNewManpower({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
        break;
      case "machines":
        setMachines([...machines, { ...newMachine, id: machines.length + 1 }]);
        setNewMachine({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
        break;
      case "materials":
        setMaterials([...materials, { ...newMaterial, id: materials.length + 1 }]);
        setNewMaterial({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
        break;
      default:
        break;
    }
  };

  const deleteEntry = (id, category) => {
    switch (category) {
      case "manpower":
        setManpower(manpower.filter((item) => item.id !== id));
        break;
      case "machines":
        setMachines(machines.filter((item) => item.id !== id));
        break;
      case "materials":
        setMaterials(materials.filter((item) => item.id !== id));
        break;
      default:
        break;
    }
  };

  const editEntry = (id, category) => {
    setEditing({ id, category });
    switch (category) {
      case "manpower":
        const manpowerToEdit = manpower.find((item) => item.id === id);
        setNewManpower(manpowerToEdit);
        break;
      case "machines":
        const machineToEdit = machines.find((item) => item.id === id);
        setNewMachine(machineToEdit);
        break;
      case "materials":
        const materialToEdit = materials.find((item) => item.id === id);
        setNewMaterial(materialToEdit);
        break;
      default:
        break;
    }
  };

  const saveEntry = (category) => {
    switch (category) {
      case "manpower":
        setManpower(manpower.map((item) => (item.id === editing.id ? newManpower : item)));
        setNewManpower({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
        break;
      case "machines":
        setMachines(machines.map((item) => (item.id === editing.id ? newMachine : item)));
        setNewMachine({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
        break;
      case "materials":
        setMaterials(materials.map((item) => (item.id === editing.id ? newMaterial : item)));
        setNewMaterial({ type: "", expertise: "", brand: "", quantity: "", date: "", startDate: "", specs: "", unit: "", dimensions: "", description: "", estimatedCost: 0 });
        break;
      default:
        break;
    }
    setEditing({ id: null, category: "" });
  };

  const tableHeaderStyle = { color: "white", fontWeight: "bold", fontSize: "1.2em", borderBottom: "2px solid white" };
  const tableBodyStyle = { color: "white", borderBottom: "1px solid white" };


  const handleSaveChanges = async () => {
    try {
      await updateProjectData(projectId, {
        manpower_requirements: manpower,
        material_requirements: materials,
        machinary_requirements: machines,
      });

      toast.success('Requirements Updated Successfully!', { position: 'top-right', autoClose: 3000 });
      setTimeout(() => {
        navigate(`/requirements-valuation?id=${projectId}`);
      }, 3000);
    } catch (error) {
      console.error('Error saving requirements data:', error);
      toast.error('Requirements Update failed!', { position: 'top-right', autoClose: 3000 });

    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "15em" }}>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>Project Requirements</Typography>
      <Typography variant="h5" gutterBottom style={{ marginBottom: "3em" }}>
        Review the predefined project requirements and apply edits where necessary. You can also delete a requirement or create a new requirement.
      </Typography>

      {/* Manpower Table and Form */}
      <Typography variant="h5" gutterBottom>Manpower Requirements</Typography>
      <Grid2 container spacing={2} style={{ marginBottom: "2em" }}>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="type" value={newManpower.type} onChange={(e) => handleInputChange(e, "manpower")} placeholder="Role" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="expertise" value={newManpower.expertise} onChange={(e) => handleInputChange(e, "manpower")} placeholder="Expertise" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="quantity" value={newManpower.quantity} onChange={(e) => handleInputChange(e, "manpower")} placeholder="Quantity" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="startDate" value={newManpower.startDate} onChange={(e) => handleInputChange(e, "manpower")} placeholder="Start Day (from start of project)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="date" value={newManpower.date} onChange={(e) => handleInputChange(e, "manpower")} placeholder="Duration(days)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="estimatedCost" value={newManpower.estimatedCost} onChange={(e) => handleInputChange(e, "manpower")} placeholder="Estimated Cost (EUR)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="description" value={newManpower.description} onChange={(e) => handleInputChange(e, "manpower")} placeholder="description" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={1}>
          {editing.category === "manpower" ? (
            <Button variant="contained" color="primary" onClick={() => saveEntry("manpower")}>Save</Button>
          ) : (
            <Button variant="contained" color="primary" onClick={() => addEntry("manpower")}>Add</Button>
          )}
        </Grid2>
      </Grid2>

      <TableContainer component={Paper} style={{ backgroundColor: "transparent", border: "1px solid white" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>Role</TableCell>
              <TableCell style={tableHeaderStyle}>Expertise</TableCell>
              <TableCell style={tableHeaderStyle}>Quantity</TableCell>
              <TableCell style={tableHeaderStyle}>Start Day</TableCell>
              <TableCell style={tableHeaderStyle}>Duration</TableCell>
              <TableCell style={tableHeaderStyle}>Estimated Cost</TableCell>
              <TableCell style={tableHeaderStyle}>Description</TableCell>
              <TableCell style={tableHeaderStyle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {manpower.map((mp) => (
              <TableRow key={mp.id}>
                <TableCell style={tableBodyStyle}>{mp.type}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.expertise}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.quantity}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.startDate}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.date}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.estimatedCost}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => editEntry(mp.id, "manpower")}><Edit style={{ color: "white" }} /></IconButton>
                  <IconButton onClick={() => deleteEntry(mp.id, "manpower")}><Delete style={{ color: "white" }} /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>



      {/* Materials Table and Form */}
      <Typography variant="h5" gutterBottom style={{ marginTop: "4em" }}>Material and Equipment Requirements</Typography>
      <Grid2 container spacing={2} style={{ marginBottom: "2em" }}>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="type" value={newMaterial.type} onChange={(e) => handleInputChange(e, "materials")} placeholder="Material Type" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="brand" value={newMaterial.brand} onChange={(e) => handleInputChange(e, "materials")} placeholder="Brand" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="quantity" value={newMaterial.quantity} onChange={(e) => handleInputChange(e, "materials")} placeholder="Quantity" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="unit" value={newMaterial.unit} onChange={(e) => handleInputChange(e, "materials")} placeholder="Unit" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="dimensions" value={newMaterial.dimensions} onChange={(e) => handleInputChange(e, "materials")} placeholder="Dimensions" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="startDate" value={newMaterial.startDate} onChange={(e) => handleInputChange(e, "materials")} placeholder="Delivery Day (from start of project)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="estimatedCost" value={newMaterial.estimatedCost} onChange={(e) => handleInputChange(e, "materials")} placeholder="Estimated Cost(EUR)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="description" value={newMaterial.description} onChange={(e) => handleInputChange(e, "materials")} placeholder="description" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={1}>
          {editing.category === "materials" ? (
            <Button variant="contained" color="primary" onClick={() => saveEntry("materials")}>Save</Button>
          ) : (
            <Button variant="contained" color="primary" onClick={() => addEntry("materials")}>Add</Button>
          )}
        </Grid2>
      </Grid2>

      <TableContainer component={Paper} style={{ backgroundColor: "transparent", border: "1px solid white" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>Material Type</TableCell>
              <TableCell style={tableHeaderStyle}>Brand</TableCell>
              <TableCell style={tableHeaderStyle}>Quantity</TableCell>
              <TableCell style={tableHeaderStyle}>Unit</TableCell>
              <TableCell style={tableHeaderStyle}>Dimensions</TableCell>
              <TableCell style={tableHeaderStyle}>Delivery Date</TableCell>
              <TableCell style={tableHeaderStyle}>Estimated Cost</TableCell>
              <TableCell style={tableHeaderStyle}>Description</TableCell>
              <TableCell style={tableHeaderStyle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials.map((mp) => (
              <TableRow key={mp.id}>
                <TableCell style={tableBodyStyle}>{mp.type}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.brand}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.quantity}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.unit}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.dimensions}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.startDate}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.estimatedCost}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => editEntry(mp.id, "materials")}><Edit style={{ color: "white" }} /></IconButton>
                  <IconButton onClick={() => deleteEntry(mp.id, "materials")}><Delete style={{ color: "white" }} /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Machines Table and Form */}
      <Typography variant="h5" gutterBottom style={{ marginTop: "4em" }}>Machines Requirements</Typography>
      <Grid2 container spacing={2} style={{ marginBottom: "2em" }}>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="type" value={newMachine.type} onChange={(e) => handleInputChange(e, "machines")} placeholder="Machine Type" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="brand" value={newMachine.brand} onChange={(e) => handleInputChange(e, "machines")} placeholder="Brand" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="specs" value={newMachine.specs} onChange={(e) => handleInputChange(e, "machines")} placeholder="Specs" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="quantity" value={newMachine.quantity} onChange={(e) => handleInputChange(e, "machines")} placeholder="Quantity" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="startDate" value={newMachine.startDate} onChange={(e) => handleInputChange(e, "machines")} placeholder="Start Day (from start of project)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="date" value={newMachine.date} onChange={(e) => handleInputChange(e, "machines")} placeholder="Duration(days)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="estimatedCost" value={newMachine.estimatedCost} onChange={(e) => handleInputChange(e, "machines")} placeholder="Estimated Cost(EUR)" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={2}>
          <TextField fullWidth variant="outlined" name="description" value={newMachine.description} onChange={(e) => handleInputChange(e, "machines")} placeholder="description" style={{ color: "white" }} />
        </Grid2>
        <Grid2 item xs={12} sm={1}>
          {editing.category === "machines" ? (
            <Button variant="contained" color="primary" onClick={() => saveEntry("machines")}>Save</Button>
          ) : (
            <Button variant="contained" color="primary" onClick={() => addEntry("machines")}>Add</Button>
          )}
        </Grid2>
      </Grid2>

      <TableContainer component={Paper} style={{ backgroundColor: "transparent", border: "1px solid white" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>Machine Type</TableCell>
              <TableCell style={tableHeaderStyle}>Brand</TableCell>
              <TableCell style={tableHeaderStyle}>Specs</TableCell>
              <TableCell style={tableHeaderStyle}>Quantity</TableCell>
              <TableCell style={tableHeaderStyle}>Start Day</TableCell>
              <TableCell style={tableHeaderStyle}>Duration</TableCell>
              <TableCell style={tableHeaderStyle}>Estimated Cost</TableCell>
              <TableCell style={tableHeaderStyle}>Description</TableCell>
              <TableCell style={tableHeaderStyle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {machines.map((mp) => (
              <TableRow key={mp.id}>
                <TableCell style={tableBodyStyle}>{mp.type}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.brand}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.specs}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.quantity}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.startDate}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.date}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.estimatedCost}</TableCell>
                <TableCell style={tableBodyStyle}>{mp.description}</TableCell>
                <TableCell>
                  <IconButton onClick={() => editEntry(mp.id, "machines")}><Edit style={{ color: "white" }} /></IconButton>
                  <IconButton onClick={() => deleteEntry(mp.id, "machines")}><Delete style={{ color: "white" }} /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Repeat similar sections for Machines and Materials with appropriate fields and styles */}
      <div className="back-arrow"
        style={{ cursor: "pointer", fontSize: "1.5em", marginTop: "2em" }}
        onClick={() => navigate(-1)}
      >
        &#8592; Back
      </div>

      <h4 style={{ marginBottom: "1em", marginTop: "4em" }}>Now, check the amount of tokens (shares) according to the value that each requirement creates in the project and prepare to hold a tender!</h4>
      <div>
        <button
          onClick={handleSaveChanges}

        >Check Requirements Valuation</button>
      </div>
    </Container>
  );
};

export default RequirementsProfile;
