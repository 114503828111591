//requirementsAPI
import axios from 'axios';

import { API_URL } from './constants';


//Fetch project data by ID.

export const fetchProjectData = async (projectId) => {
  try {
    const response = await axios.get(`${API_URL}/requirements`, {
      params: {
          projectId: projectId
      }
  });
  console.log('Fetched requirements from the backend:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching project data:', error);
    throw error;
  }
};


//Update project data by ID.

export const updateProjectData = async (projectId, updatedData) => {
  try {
    const response = await axios.put(`${API_URL}/requirements`, updatedData, {
      params: {
          projectId: projectId
      }
    });
    console.log('New requirements Put to the backend:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating project data:', error);
    throw error;
  }
};
