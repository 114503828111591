import React from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import BuyCoin1 from '../components/layouts/buycoinowner/buycoin.jsx';
import dataAuthor from '../assets/fake-data/data-coins.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';


function BuyCoinOwner(props) {
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            {<PageTitle title='Select & Buy Coins'/>}
            {<BuyCoin1 data={dataAuthor}/>}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default BuyCoinOwner;