//requirementsAPI
import axios from 'axios';

import { API_URL } from './constants';


//Fetch project data by ID.

export const fetchProjectData = async (projectId) => {
  try {
    const response = await axios.get(`${API_URL}/requirements-valuation`, {
      params: {
          projectId: projectId
      }
  });
  console.log('Fetched requirements from the backend:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching project data:', error);
    throw error;
  }
};


//Update project data by ID.

export const updateProjectData = async (projectId, updatedData) => {
  try {
    const response = await axios.put(`${API_URL}/requirements-valuation`, updatedData, {
      params: {
          projectId: projectId
      }
    });
    console.log('New requirements Put to the backend:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating project data:', error);
    throw error;
  }
};

// Update the project status
export const updateProjectStatus = async (projectId, totalTokens) => {
  try {
    const response = await axios.put(`${API_URL}/requirements-valuation`,{}, {
      params: {
          projectId: projectId,
          totalTokens: totalTokens
      }
  });
  console.log('projId in API:', projectId);
  console.log('totalTokens in API:', totalTokens);
  console.log('status to the backend:', response.data);
  return response.data;
  } catch (error) {
    console.error('Error updating project status:', error);
    throw error;
  }
};

// Update the owner wallet
export const updateOwnerWallet = async (owner_email,ownerWallet) => {
  try {
    const response = await axios.put(`${API_URL}/requirements-valuation/ownerwallet`,{}, {
      params: {
        owner_email: owner_email,
        ownerWallet : ownerWallet
      }
  });
  console.log('owner_email in API:', owner_email);
  console.log('ownerWallet in API:', ownerWallet)

  console.log('wallet to the backend:', response.data);
  return response.data;
  } catch (error) {
    console.error('Error updating ownerWallet:', error);
    throw error;
  }
};


//post to subproject table
export const postSubprojects = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/requirements-valuation`, payload);
    return response.data;
  } catch (error) {
    console.error('Error posting subproject:', error);
    throw error;
  }
};
