import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import { finishProject, fetchData, sendMessage } from '../api/ownerPMApi.js';
import { fileBaseUrl } from '../api/constants.js';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import { Link } from "react-router-dom";

import img1 from '../assets/images/icon/check.png';
import img2 from '../assets/images/blog/blog-detail-4.png';

import img3 from '../assets/images/avatar/avt-01.png';
import img4 from '../assets/images/avatar/avt-02.png';
import img5 from '../assets/images/avatar/avt-03.png';
//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function JuryDetails(props) {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const subProjectId = searchParams.get('id');
    console.log('subProjectId:', subProjectId);
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [project, setProject] = useState([]);
    const [notification, setNotification] = useState('');




    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <PageTitle title='Project-Management' />
            <div className="tf-section flat-blog-details ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <article className="post">
                                <div className="inner-content">
                                    <h2 className="title">{project.title}</h2>
                                    <div className="sc-card-article">                                  
                                        <div className="next-arrow"
                                            style={{ cursor: "pointer", fontSize: "1.5em" }}
                                            onClick={() => navigate(-1)}
                                        >
                                            &#8592; Back
                                        </div>
                                    </div>
                                    <p className="text-boder">{project.project_description}</p>
                                </div>
                                <div id="comments">
                                    <ol className="comment-list">
                                        {conversations && conversations.map((conversation) => (
                                            // Check if there is a message
                                            conversation.message && (
                                                <li
                                                    className="comment-01 flex mb-44"
                                                    key={conversation.id}
                                                    style={{
                                                        flexDirection: conversation.owner_email === conversation.sender_email ? 'row-reverse' : 'row',
                                                        textAlign: conversation.owner_email === conversation.sender_email ? 'right' : 'left'
                                                    }}
                                                >

                                                    <div className="comment-content"

                                                    >
                                                        <div className="comment-meta">
                                                            {/* Render owner's first name if owner sent the message, otherwise render partner's first name */}
                                                            <div className="comment-author mb-18">
                                                                <h3>{conversation.owner_email === conversation.sender_email ? conversation.owner_first_name : conversation.partner_first_name}</h3>
                                                                {/* Display the date of the message */}
                                                                <span className="message-date">{new Date(conversation.created_at).toLocaleDateString()}</span>
                                                            </div>
                                                        </div>
                                                        <div className="comment-text">
                                                            <p>{conversation.message}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        ))}
                                    </ol>
                                    <div id="respond" className="respond-comment">
                                        <div className="title-comment2 font-20-600">Write your judgment</div>
                                        <form id="contactform" className="comment-form form-submit"
                                            action="#" method='post' acceptCharset="utf-8"
                                        >

                                            <fieldset className="message-wrap">
                                                <textarea id="comment-message" name="message" rows="3" tabIndex="4"
                                                    placeholder="My Opinion"
                                                    required></textarea>
                                            </fieldset>

                                            <button name="submit" type="submit" id="comment-reply" style={{ marginRight: "4em" }}
                                                className="button btn-submit-comment"><span>Tokens paid to partner </span></button>
                                            <button name="submit" type="submit" id="comment-reply"
                                                className="button btn-submit-comment"><span>Tokens seized from partner</span></button>
                                        </form>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="side-bar">
                            <div className="inner-side-bar">
                                    <div className="widget widget-categories style">
                                        <div className="widget-title title-list">
                                            Files
                                        </div>
                                        {conversations && conversations.map((conversation) => (
                                            // Check if there are file URLs or messages
                                            (conversation.file_urls && conversation.file_urls.length > 0) && (
                                                <li className="comment-01 flex mb-44" key={conversation.id}>
                                                    <div className="comment-content">
                                                        <ul>
                                                            {/* Map through file URLs and render anchor elements */}
                                                            {conversation.file_urls.map((fileUrl, index) => {
                                                                // Check if fileUrl is not null or undefined
                                                                if (fileUrl) {
                                                                    // Extract the file name from the file URL
                                                                    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
                                                                    const extractedFileName = fileName.split(/[\\/]/).pop();

                                                                    // Extract the creation date from the conversation object
                                                                    const creationDate = new Date(conversation.created_at).toLocaleDateString();

                                                                    // Replace backslashes with forward slashes and remove any leading dots
                                                                    const cleanRelativePath = fileUrl.split(/[\\/]/).pop();

                                                                    // Construct the complete download URL
                                                                    const fileDownloadUrl = fileBaseUrl + '/' + cleanRelativePath;

                                                                    // Display the file name and creation date
                                                                    return (
                                                                        <li key={index}>
                                                                            <a href={fileDownloadUrl} download>{extractedFileName} ({creationDate})</a>
                                                                        </li>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        ))}
                                        
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default JuryDetails;