
import Home1 from "./Home1";
import Explore from "./Explore";
import About from "./About";
import Author from "./Author";
//import Profile from "./Profile";
//import EditProfile from "./EditProfile";
import EditProfileOwner from "./EditProfile-Owner";
import EditProfilePartner from "./EditProfile-Partner";
import OwnerLogin from "./OwnerLogin";
import OwnerRegister from "./OwnerRegister";
import PartnerLogin from "./PartnerLogin.jsx";
import PartnerRegister from "./PartnerRegister.jsx";
import BuyerLogin from "./BuyerLogin.jsx";
import BuyerRegister from "./BuyerRegister.jsx";
import JuryLogin from "./JuryLogin.jsx";
import Contact from "./Contact";
import OwnerDashboard from "./OwnerDashboard";
import Applicants from "./Applicants";
import ProjectProposal from "./ProjectProposal";
import OwnerProjectManagement from "./OwnerProjectManagement";
import OwnerProjectArchive from "./OwnerProjectArchive";
import PartnerDashboard from "./PartnerDashboard.jsx";
import BuyerDashboard from "./BuyerDashboard.jsx";
import ProjectDetail from "./ProjectDetail";
import BuyCoinOwner from "./BuyCoinOwner";
import BuyCoinPartner from "./BuyCoinPartner.jsx";
import PartnerProjectManagement from "./PartnerProjectManagement.jsx";
import PartnerProjectArchive from "./PartnerProjectArchive.jsx";
import ProjectDetailOwner from "./ProjectDetailOwner";
import EditProject from "./EditProject";
import Requirement from "./Requirements.jsx";
import RequirementsEstimation from "./RequirementsEstimation.jsx";
import CreateItem from "./CreateItem.jsx"
import LiveAuction1 from "./LiveAuction.jsx";
import LiveAuctionDetails from "./LiveAuctionDetails.jsx"
import Jury from "./Jury.jsx";
import JuryDetails from "./JuryDetails.jsx";
import Bidders from "./Bidders.jsx";


const routes = [
    { path: '/', component: <Home1 />},
    { path: '/explore', component: <Explore />},
    { path: '/about', component: <About />},
    { path: '/author', component: <Author />},
    //{ path: '/profile', component: <Profile />},
   // { path: '/editProfile', component: <EditProfile />},
    { path: '/editProfile-owner', component: <EditProfileOwner />},
    { path: '/editProfile-partner', component: <EditProfilePartner />},
    { path: '/login-owner', component: <OwnerLogin />},
    { path: '/register-owner', component: <OwnerRegister />},
    { path: '/login-partner', component: <PartnerLogin />},
    { path: '/register-partner', component: <PartnerRegister />},
    { path: '/login-buyer', component: <BuyerLogin />},
    { path: '/register-buyer', component: <BuyerRegister />},
    { path: '/login-jury', component: <JuryLogin />},
    { path: '/contact', component: <Contact />},
    { path: '/dashboard-owner', component: <OwnerDashboard />},
    { path: '/project-applicants', component: <Applicants />},
    { path: '/project-proposal', component: <ProjectProposal />},
    { path: '/owner-project-management', component: <OwnerProjectManagement />},
    { path: '/owner-project-archive', component: <OwnerProjectArchive />},
    { path: '/dashboard-partner', component: <PartnerDashboard />},
    { path: '/dashboard-buyer', component: <BuyerDashboard />},
    { path: '/project-detail', component: <ProjectDetail />},
    { path: '/buy-coin-owner', component: <BuyCoinOwner />},
    { path: '/buy-coin-partner', component: <BuyCoinPartner />},
    { path: '/partner-project-management', component: <PartnerProjectManagement />},
    { path: '/partner-project-archive', component: <PartnerProjectArchive />},
    { path: '/owner-project-detail', component: <ProjectDetailOwner />},
    { path: '/owner-edit-request', component: <EditProject />},
    { path: '/requirements', component: <Requirement />},
    { path: '/requirements-valuation', component: <RequirementsEstimation />},
    { path: '/set-auction', component: <CreateItem />},
    { path: '/live-auctions', component: <LiveAuction1 />},
    { path: '/liveAuctionDetails', component: <LiveAuctionDetails />},
    { path: '/jury', component: <Jury />},
    { path: '/jury-details', component: <JuryDetails />},
    { path: '/bidders', component: <Bidders />},











  ]
  
  export default routes;