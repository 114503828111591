//const API_URL = "http://localhost:5005/api"; //Local Address
const API_URL = "https://api.buildynamics.com/api"; // Server Address

export { API_URL };

///File Location
//const fileBaseUrl = "http://localhost:5005/uploads"; // Change this to your server's base URL
const fileBaseUrl = "https://api.buildynamics.com/uploads"; // Server Address
export { fileBaseUrl };

//const fileBaseUrl2 = "http://localhost:5005"; // Change this to your server's base URL
const fileBaseUrl2 = "https://api.buildynamics.com"; // Server Address
export { fileBaseUrl2 };

// Web3 Initialization
const platformWalletAddress = "0xae637203d64672e813B6Fa8c2D3D3315FBb35a41";
export { platformWalletAddress };

const PLATFORM_PRIVATE_KEY =
  "fa1ce5c8f94d7d516ccb4a685a8eeac441e45a5d8162c841314a575a2d7d73e4";
export { PLATFORM_PRIVATE_KEY };

const INFURA_URL =
  "https://sepolia.infura.io/v3/b61958b591624a369c6f986a1ccc09f8";
export { INFURA_URL };
