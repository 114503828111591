import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchAuctions } from '../api/biddersApi.js';

import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Bidder from '../components/layouts/bidders/bidders.jsx';
import dataApplicants from '../assets/fake-data/data-applicants.js';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';

//import img1 from '../assets/images/icon/icon1.svg';
import img1 from '../assets/images/icon/icon2.svg';
import img3 from '../assets/images/icon/icon3.svg';

import icon1 from '../assets/images/icon/token1.png';
import icon2 from '../assets/images/icon/budget.png';

function Bidders(props) {

    //Fetch Project auction from Backend

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');

    const [auctionsData, setAuctionsData] = useState([]); // State to store fetched auctions data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const auctions = await fetchAuctions(projectId); // Fetch auctions data from backend API
                setAuctionsData(auctions[0]); // Set fetched auctions data to state
                console.log('auctions data in front', auctions[0])
            } catch (error) {
                console.error('Error fetching auctions:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);



    // Map the fetched auctions data to match the structure of dataLiveaution
    const mappedAuctionsData = auctionsData.map((auction, index) => ({
        id: `${auction.auction_id}`,
        img: img1,
        tags: '6 New partners',
        title: `${auction.buyer_email}`,
        price: `${auction.buyer_value}`,
        token:`${auction.partner_token}`,
        time: `${auction.creation_date}`,
        btn: 'Accept this bid!',
        icon1: icon1,
        icon2: icon2,
        projectId: `${auction.project_id}`,
        auctionId: `${auction.auction_id}`,
    }));

    // Determine the number of rows to display based on the length of the fetched auctions data
    const numRowsToShow = mappedAuctionsData.length > 3 ? 3 : mappedAuctionsData.length;

    // Slice the mapped auctions data to display only the required number of rows
    const slicedAuctionsData = mappedAuctionsData.slice(0, numRowsToShow);

    // Combine the mapped auctions data with the remaining dataLiveaution
    //const updatedDataLiveaution = [...mappedAuctionsData, ...slicedAuctionsData.slice(mappedAuctionsData.length)];
    const updatedDataLiveaution = mappedAuctionsData; // No slicing here

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Project Applicants' />
            {<Bidder data={updatedDataLiveaution} />}
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default Bidders;
