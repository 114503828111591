import axios from 'axios';

import { API_URL } from './constants';

export const fetchPartnerData = async (email) => {
    try {
        const response = await axios.get(`${API_URL}/partner`, {
            params: {
                email: email
            }
        });
        console.log('Fetched partner data from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};



