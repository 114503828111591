import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { fetchProjectData, updateProjectStatus, postSubprojects, updateOwnerWallet } from "../../../api/valuationApi.js"; // Import API functions
//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

const EstimationProfile = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [projectData, setProjectData] = useState({
    initialManpower: [],
    initialMachines: [],
    initialMaterials: [],
  });
  const [manpower, setManpower] = useState([]);
  const [machines, setMachines] = useState([]);
  const [materials, setMaterials] = useState([]);

  const projectId = new URLSearchParams(window.location.search).get('id');

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const data = await fetchProjectData(projectId);
        console.log("fetched data from back", data);
        const { manpower_requirements, material_requirements, machinary_requirements } = data;
        setProjectData({
          initialManpower: manpower_requirements || [],
          initialMachines: machinary_requirements || [],
          initialMaterials: material_requirements || [],
        });
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };
    getProjectData();
  }, [projectId]);

  // Sync other states with projectData
  useEffect(() => {
    setManpower(projectData.initialManpower);
    setMachines(projectData.initialMachines);
    setMaterials(projectData.initialMaterials);
  }, [projectData]);

  console.log('project Data:', projectData);
  console.log('manpower:', manpower);

  const requirements = [
    ...manpower.map(item => ({ ...item, category: 'manpower' })),
    ...machines.map(item => ({ ...item, category: 'machines' })),
    ...materials.map(item => ({ ...item, category: 'materials' })),
  ];
  console.log("requirements:", requirements)

  //const partnerTotalTokens = 100000; // Total tokens for the project
  // Add `npv` to each item
  const discountRate = 0.05
  const dailyDiscountRate = discountRate / 365; //Daily Discount Rate for calculating NPV
  const requirementsWithNPV = requirements.map(item => {
    const npv = item.estimatedCost / Math.pow((1 + dailyDiscountRate), item.startDate);
    return { ...item, npv: Math.round(npv) };
  });
  console.log("requirementsWithNPV:", requirementsWithNPV);

  // Calculate the total estimatedCost and tokens
  const totalEstimatedCost = requirements.reduce(
    (acc, item) => acc + Number(item.estimatedCost), // Ensure type is a number
    0
  );
  console.log("Calculated total estimated cost:", totalEstimatedCost);

  // Calculate the total NPV and tokens
  const totalNpv = requirementsWithNPV.reduce(
    (acc, item) => acc + Number(item.npv), // Ensure type is a number
    0
  );
  console.log("Calculated total npv:", totalNpv);

  const partnerTotalTokens = totalNpv; // Total tokens for the project

  //add percentage to items
  const updatedRequirements = requirementsWithNPV.map(item => ({
    ...item,
    percentage: parseFloat(((Number(item.npv) / totalNpv) * 100).toFixed(2)),
  }));

  console.log("Updated requirements with percentages:", updatedRequirements);

  const requirementsWithTokens = updatedRequirements.map((item) => ({
    ...item,
    tokens: Math.round((item.percentage / 100) * partnerTotalTokens),
  }));
  console.log("final requirement:", requirementsWithTokens);
  // Prepare data for Pie Chart
  const pieData = {
    labels: requirementsWithTokens.map((item) => item.type),
    datasets: [
      {
        data: requirementsWithTokens.map((item) => item.percentage),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"], // Modern and attractive colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
      },
    ],
  };

  const tableHeaderStyle = { color: "white", fontWeight: "bold", fontSize: "1.2em", borderBottom: "2px solid white" };
  const tableBodyStyle = { color: "white", borderBottom: "1px solid white" };

  //wallet connection
  let web3;

  const connectWallet = async () => {
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        return accounts[0]; // Return the first account (user wallet)
      } catch (error) {
        console.error("User denied account access:", error);
      }
    } else {
      console.error("Please install Metamask!");
    }
  };

  const [ownerToken, setOwnerToken] = useState(0); // Initialize ownerToken state
  const [totalTokens, setTotalTokens] = useState(0); // Initialize ownerToken state

  const handleInputChange = (event) => {
    const value = Number(event.target.value); // Convert input value to a number
    setOwnerToken(value); // Update ownerToken state
    setTotalTokens (value + partnerTotalTokens);

    console.log('Owner Token:', value); // Log for debugging
    console.log('Total Token ', value + partnerTotalTokens); // Log for debugging

  };

  const handleSubmit = async () => {

    try {
      const ownerWallet = await connectWallet();
      console.log("ownerWallet:", ownerWallet);
      if (!ownerWallet) {
        alert("Metamask connection failed!");
        return;
      }
      const owner_email = localStorage.getItem("email");
      await updateOwnerWallet(owner_email, ownerWallet);

      // Iterate over requirementsWithTokens and send each item to the backend
      for (const item of requirementsWithTokens) {
        const payload = {
          project_id: projectId,
          project_name: localStorage.getItem("projectName"),
          owner_email: localStorage.getItem("email"),
          owner_first_name: localStorage.getItem("firsName"),
          owner_last_name: localStorage.getItem("lastName"),
          type: item.type || null,
          expertise: item.expertise || null,
          brand: item.brand || null,
          quantity: item.quantity || null,
          date: item.date || null,
          startDate: item.startDate || null,
          specs: item.specs || null,
          unit: item.unit || null,
          dimensions: item.dimensions || null,
          description: item.description || null,
          estimatedCost: item.estimatedCost || null,
          percentage: item.percentage || null,
          tokens: item.tokens || null,
        };

        await postSubprojects(payload); // Post each item to the backend
      }
      // Update the project status to "tendering"
      await updateProjectStatus(projectId, totalTokens);

      toast.success('Congratulation, Tendering is started!, check Tendering Tab', { position: 'top-right', autoClose: 3000 });
      setTimeout(() => {
        navigate(`/dashboard-owner`);
      }, 4000);
    } catch (error) {
      console.error('Error posting subprojects:', error);
      alert('Failed to create subprojects. Please try again.');
    }
  };


  return (
    <Container maxWidth="md" style={{ marginTop: "15em" }}>
      <ToastContainer />

      <Typography variant="h4" gutterBottom>Project Requirements Valuation</Typography>

      {/* Requirements Table */}
      <TableContainer component={Paper} style={{ backgroundColor: "transparent", border: "1px solid white", marginBottom: "2em", marginTop: "2em" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>Number</TableCell>
              <TableCell style={tableHeaderStyle}>Category</TableCell>
              <TableCell style={tableHeaderStyle}>Requirement Title</TableCell>
              <TableCell style={tableHeaderStyle}>estimatedCost</TableCell>
              <TableCell style={tableHeaderStyle}>Percentage of Project Value</TableCell>
              <TableCell style={tableHeaderStyle}>Partner Tokens</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requirementsWithTokens.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell style={tableBodyStyle}>{index + 1}</TableCell>
                <TableCell style={tableBodyStyle}>{item.category}</TableCell>
                <TableCell style={tableBodyStyle}>{item.type || 'No Name available'}</TableCell>
                <TableCell style={tableBodyStyle}>{`EUR ${item.estimatedCost.toLocaleString()}`}</TableCell>
                <TableCell style={tableBodyStyle}>{`${item.percentage}%`}</TableCell>
                <TableCell style={tableBodyStyle}>{item.tokens}</TableCell>
              </TableRow>
            ))}
            {/* Total Row */}
            <TableRow>
              <TableCell style={tableHeaderStyle} colSpan={3}>Total</TableCell>
              <TableCell style={tableHeaderStyle}>{`EUR ${totalEstimatedCost.toLocaleString()}`}</TableCell>
              <TableCell style={tableHeaderStyle}>100%</TableCell>
              <TableCell style={tableHeaderStyle}>{partnerTotalTokens}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h4" gutterBottom style={{ marginTop: "2em", marginBottom: "1em" }}>Owner Tokens</Typography>
      <Typography variant="h5" gutterBottom>Enter the number of owner tokens. Consider the ratio of the number of owner tokens to the total number of partner tokens. The total number of partner and owner tokens will remain constant throughout the project.</Typography>

      <input type="number" placeholder="Enter Number of Your Tokens" name="owner_tokens" style={{ maxHeight: "2.6em", marginTop: "1em", marginBottom: "4em" }} required
        onChange={handleInputChange}// Call handleInputChange on input change
      />

      {/* Pie Chart */}
      <Typography variant="h5" gutterBottom>Requirements Distribution</Typography>
      <div style={{ width: "50%", height: "50%", margin: "0 auto", marginTop: "2em" }}>
        <Pie data={pieData} />
      </div>
      <div className="back-arrow"
        style={{ cursor: "pointer", fontSize: "1.5em" }}
        onClick={() => navigate(-1)}
      >
        &#8592; Back & Edit Requirements
      </div>

      <h4 style={{ marginBottom: "1em", marginTop: "4em" }}>Or if you are sure of all the requirements, start tendering now to find your best partners!</h4>

      <div>
        <button
          onClick={handleSubmit}

        >Initiate Tender</button>
      </div>
    </Container>
  );
};

export default EstimationProfile;
