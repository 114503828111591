import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useLocation } from 'react-router-dom';

import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import dataProduct from '../assets/fake-data/data-liveaution';
import { Link } from 'react-router-dom';
import img1 from '../assets/images/box-item/edit-profile-2.jpg';
import CardModal from ".././components/CardModal";
import { Dropdown } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { fetchRequests, updateAuctionDetails, fetchPhoto, uploadPhoto } from '../api/createItemApi.js'; // Import the fetchRequests function
import { fileBaseUrl2 } from '../api/constants.js';
//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateItem(props) {
    const [modalShow, setModalShow] = useState(false);

    //Fetch Project Request from Backend
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data

    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('id');
    const [projectPhoto, setProjectPhoto] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const photoPath = await fetchPhoto(projectId); // Fetch normalized photo path
                const fullPhotoUrl = `${fileBaseUrl2}/${photoPath}`; // Construct full URL
                setProjectPhoto(fullPhotoUrl); // Set full URL to state
            } catch (error) {
                console.error('Error fetching project photo:', error);
            }
        };

        fetchData();
    }, [projectId]);



    console.log("projectPhoto", projectPhoto);


    ///project photo upload
    const handlePhotoUpload = async (event) => {
        event.preventDefault();
        if (!projectId) {
            console.log("No projectId found in URL.");
            return;
        }
        const formData = new FormData();
        //formData.append('projectPhoto_link', event.target.files);
        formData.append('projectPhoto', event.target.files[0]);
        console.log("sending file:", event.target.files[0]);
        formData.append('project_id', projectId);
        try {
            const response = await uploadPhoto(projectId, formData);
            const projectPhotoPath = `${fileBaseUrl2}/${response.projectPhotoPath}`; // Ensure the full URL is set
            setProjectPhoto(projectPhotoPath);
            toast.success("projectPhoto uploaded successfully!");
        } catch (error) {
            console.error("Error uploading projectPhoto:", error);;
            toast.error("Error uploading projectPhoto.");
        }
    };



    const [minimumBid, setMinimumBid] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await updateAuctionDetails(projectId, { minimum_bid: minimumBid, auction_description: description });
            if (response.success) {
                toast.success("Auction Set and Activated Successfully!");
                setTimeout(() => {
                    // Redirect to the next page after 3 seconds
                    // Replace '/next-page' with the route you want to navigate to
                    window.location.href = '/dashboard-owner';
                }, 3000);
            } else {
                toast.error("Error in setting auction.");
            }
        } catch (error) {
            console.error('Error updating auction details:', error);
            toast.error("Error in setting auction.");
        }
    };




    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Create Item' />
            <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
            <Fragment>
                <div className="tf-section flat-create-item flat-edit-profile flat-auctions-details flat-explore flat-auctions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 ">
                                <div className="sc-card-product sc-card-article blog-article">
                                    <Link to="live-auctions-details.html">
                                        {projectPhoto ? (
                                            <img src={projectPhoto} alt="Project" />
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </Link>
                                    <div className="content">
                                        <h3><Link to="live-auctions-details.html">"Porjec X"</Link></h3>
                                    </div>
                                    <div id="upload-profile">
                                        <Link className="btn-upload" onChange={handlePhotoUpload}>Upload Project Photo</Link>
                                        <input id="tf-upload-img" type="file" onChange={handlePhotoUpload} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8 col-md-12">
                                <div className="form-upload-profile post">
                                    <h3 className="title-one fw-6">Set Project Auction</h3>
                                    <form className="form-profile" onSubmit={handleSubmit}>
                                        <div className="flat-tabs tab-create-item flat-form">
                                            <div className="info-title info-account info-royalties">
                                                <fieldset>
                                                    <p className="title-infor-account">Minimum bid (in ETH)</p>
                                                    <input
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Enter Minimum Bid (in ETH)"
                                                        value={minimumBid}
                                                        onChange={(e) => setMinimumBid(e.target.value)}
                                                        required
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="form-infor-profile flat-form">
                                            <fieldset className="message">
                                                <p className="title-infor-account">Description</p>
                                                <textarea
                                                    rows="3"
                                                    placeholder="Enter auction description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    required
                                                />
                                            </fieldset>
                                        </div>
                                        <button style={{ marginTop: '4em', marginBottom: '4em' }} type="submit">
                                            Launch Auction
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
            {<Footer data={dataFooter} />}
            {<Bottom classNameection='widget-social-footer' />}
        </div>
    );
}

export default CreateItem;