import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import { fetchProposalById, AcceptPartner, fetchProposalFile } from '../../../api/projectProposalApi';

import { Link } from "react-router-dom";
import CardModal from "../../CardModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from '../../button/button-st1';
import img1 from '../../.././assets/images/box-item/author-profile.jpg';
import { fileBaseUrl2 } from '../../../api/constants';

//showing messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProposalProfile = (props) => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const data = props.data;
    const [modalShow, setModalShow] = useState(false);



    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const proposalId = searchParams.get('id');

    const [proposal, setProposal] = useState(null);


    useEffect(() => {
        const fetchProposal = async () => {
            try {
                const proposalData = await fetchProposalById(proposalId);
                setProposal(proposalData);
            } catch (error) {
                console.error('Error fetching Proposal details:', error);
            }
        };
        fetchProposal();
    }, [proposalId]);


    console.log('Proposal by id in front:', proposal);


    //proposal submission

    const [requestSuccess, setRequestSuccess] = useState(false); // New state for success message

    const handleDownload = async () => {
        try {
            const fileData = await fetchProposalFile(proposalId);
            const fileUrl = fileData.fileUrl;
    
            // Extract the file name from the URL
            const fileName = fileUrl.split('\\').pop();
            console.log('Extracted file name:', fileName);
    
            // Trigger file download
            const link = document.createElement('a');
            link.href = `${fileBaseUrl2}/${fileUrl}`; // Append base API URL to file URL if necessary
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Error downloading file. Please try again later.');
        }
    };
    
    


    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            // Submit the proposal to the backend
            await AcceptPartner({
                subProjectId: proposal[0].sub_id,
                partnerEmail: proposal[0].partner_email,
                partnerFirstName: proposal[0].partner_first_name,
                partnerLastName: proposal[0].partner_last_name,
                partnerCost: proposal[0].partner_cost,
                partnerToken: proposal[0].partner_token,
                status: 'doing',
            });

            setRequestSuccess(true); // Set the registration success state to true

            // Show success notification
            toast.success('Congratulation, Start Your Project!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setTimeout(() => {
                // Redirect to the next page after 3 seconds
                // Replace '/next-page' with the route you want to navigate to
                window.location.href = `/owner-project-management?id=${proposal[0].sub_id}`;
            }, 3000);

        } catch (error) {
            console.error('Error Starting Cooperation:', error);
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors[0].msg;
            } else {
                toast.error('Error Starting Cooperation!', {
                    // Toast configuration for other registration errors
                });
            }
        }
    };

    if (!proposal) {
        return <div>Loading...</div>;
    }
    return (
        <Fragment>
            <div className="tf-section flat-author-profile flat-explore flat-auctions " style={{ marginBottom: '0em' }}>
                <ToastContainer /> {/* Add the ToastContainer component for showing messages */}
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex">
                            <div className="feature-profile flex">
                                <div className="infor">
                                    <h3 className="fs-24 text-color-1">{proposal[0].partner_first_name} {proposal[0].partner_last_name}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tf-section flat-about" style={{ marginTop: '0em' }}>
                    <div className="container">
                        <div className="post">
                            <h2 className="title-about text-t">Detail of Proposal</h2>
                            <p className="text-1">{proposal[0].proposal_description}</p>

                            <button
                                style={{ width: "15em", height: "3em", padding: "0.2em", color: "primary", backgroundColor: "black", marginTop: "1em" }}
                                onClick={handleDownload} // Attach download handler
                            > Download Attached Files</button>

                            <div className="box" style={{ marginTop: '4em' }}>
                                <div className="title-text fs-18 fw-4 text-color-3" style={{ marginBottom: '0' }} >My suggested cost for this requiremnt is: EUR {proposal[0].partner_cost}  </div>
                                <div className="title-text fs-18 fw-4 text-color-3">My suggested tokens for this requirement is: {proposal[0].partner_token} Tokens </div>

                                <button
                                    onClick={handleAccept}
                                > Accept and start cooperation</button>
                            </div>
                        </div>
                        <div className="back-arrow"
                            style={{ cursor: "pointer", fontSize: "1.5em" }}
                            onClick={() => navigate(-1)}
                        >
                            &#8592; Back
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>

    );
};


export default ProposalProfile;
