// my-app/src/api/buyerDashboardApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchRequests = async () => {
    try {
        const response = await axios.get(`${API_URL}/live-auctions`, {});
        console.log('Fetched your requests from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateBuyerWallet = async (buyer_email,buyerWallet) => {
    try {
      const response = await axios.put(`${API_URL}/live-auctions/buyerwallet`,{}, {
        params: {
          buyer_email: buyer_email,
          buyerWallet : buyerWallet
        }
    });
    console.log('buyer_email in API:', buyer_email);
    console.log('buyerWallet in API:', buyerWallet)
  
    console.log('wallet to the backend:', response.data);
    return response.data;
    } catch (error) {
      console.error('Error updating buyerWallet:', error);
      throw error;
    }
  };

export const fetchBuyerRequests = async (buyerEmail) => {
    try {
        console.log('buyerEmail in API', buyerEmail);
        const response = await axios.get(`${API_URL}/dashboard-buyer`, {
            params: {
                buyerEmail: buyerEmail
            }
        });
        console.log('Fetched your requests from the backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};



//Plan
export const uploadPlan = async (projectId, formData) => {
    console.log("projectId in front API:",projectId);
    if (!projectId || !formData) {
        throw new Error("Project ID or form data is missing");
    }

    try {
        const response = await axios.post(
            `${API_URL}/dashboard-buyer/upload-plan`, 
            formData, 
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                params: { projectId } // Pass project ID as a query parameter
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error uploading plan:", error);
        throw error;
    }
};



export const fetchPlan = async (email) => {
    try {
        const response = await axios.get(`${API_URL}/dashboard-buyer/plan`, {
            params: { email }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching plan:', error);
        throw error;
    }
};

export const deletePlan = async (email) => {
    try {
        const response = await axios.delete(`${API_URL}/editProfile-buyer/delete-plan`, {
            data: { email }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting plan:', error);
        throw error;
    }
};
