// my-app/src/api/projectDatailApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchProposalById = async (proposalId) => {
    try {
        const response = await axios.get(`${API_URL}/project-proposal?id=${proposalId}`);
        console.log('fetched proposal detail from backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const AcceptPartner = async (proposalData) => {
    try {
        await axios.post(`${API_URL}/project-proposal?id=${proposalData.subProjectId}`, proposalData);
        console.log('post partners to backend:', proposalData);
        // Optionally, handle success cases
    } catch (error) {
        throw error;
    }
};

export const fetchProposalFile = async (proposalId) => {
    try {
        const response = await axios.get(`${API_URL}/project-proposal/file?id=${proposalId}`);
        console.log('Fetched proposal file URL from backend:', response.data);
        
        // Handle the nested array structure
        const fileLink = response.data.fileUrl; 
        if (!fileLink) throw new Error('File link not found in response');
        
        return { fileUrl: fileLink };
    } catch (error) {
        console.error('Error fetching file URL:', error);
        throw error;
    }
};

