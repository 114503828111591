import React, { useEffect, useState, Fragment } from 'react';
import '../scss/components/section.scss';
import Header from '../components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1/index.jsx';
import Flathelp from '../components/flat-help/index.jsx';
import Footer from '../components/footer/footer-v1/index.jsx';
import dataFooter from '../assets/fake-data/data-footer.js';
import Bottom from '../components/bottom/index.jsx';
import BlogItem from '../components/blog/blog-item2/index.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid } from 'swiper';
import dataBlog from '../assets/fake-data/data-blog.js';
import { Link } from "react-router-dom";
import { fetchSubProjectData } from '../api/juryApi.js'; // Import the fetchRequests function

function Jury(props) {

    //const navigate = useNavigate(); // Initialize useNavigate hook
    const [requestsData, setRequestsData] = useState([]); // State to store fetched Requests data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const requests = await fetchSubProjectData(); // Fetch Requests data from backend API
                setRequestsData(requests); // Set fetched Requests data to state
                console.log('subprojects in jury status:', requests)
            } catch (error) {
                console.error('Error fetching Requests:', error);
                // Handle error
            }
        };
        fetchData();
    }, []);



    const itemsPerRow = 3; // Number of items per row
    const rows = Math.ceil(requestsData.slice(4, 12).length / itemsPerRow); // Calculate rows dynamically

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <PageTitle title='Blog Page' />
            <div className="tf-section dark-style2 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <Swiper
                                className='swiper-container carousel-blog style-2 btn-collection2'
                                modules={[Navigation, Grid]}
                                spaceBetween={30}
                                navigation
                                grid={{
                                    rows: rows, // Set dynamic number of rows
                                }}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: itemsPerRow,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {
                                    requestsData.slice(0, 10000).map(item => (
                                        <SwiperSlide key={item.id}>
                                            <div className="sc-card-product sc-card-article blog-article">
                                                <div className="content">
                                                    <h3><Link to={{ pathname: "/jury-details", search: `?id=${item.sub_id}` }}>
                                                        Project Name: {item.project_name}
                                                    </Link></h3>
                                                    <div className="meta-info mb-17">
                                                        <div className="author">

                                                            <div className="info">
                                                                <h4 style={{ marginTop: "1em" }}> Owner Claim:<p>{item.owner_jury_description}</p> </h4>
                                                                <h4 style={{ marginTop: "1em" }}> Partner Claim:<p>{item.partner_jury_description}</p> </h4>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="meta-price flex-one">
                                                        <div className="button-place-bid">
                                                            <Link className="sc-button btn-3" to={{ pathname: "/jury-details", search: `?id=${item.sub_id}` }}
                                                            style={{height:"4em", padding:"1em"}}
                                                            ><span>Check Details </span></Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            {<Footer data={dataFooter} />}
            {<Bottom classection='widget-social-footer' />}
        </div>
    );
}

export default Jury;
