// my-app/src/api/projectDatailApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchProjectById = async (subProjectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-detail?id=${subProjectId}`);
        console.log('fetched project detail from backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchSubProjectById = async (subProjectId) => {
    try {
        const response = await axios.get(`${API_URL}/project-detail/subproject?id=${subProjectId}`);
        console.log('fetched project detail from backend:', response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const submitProposal = async (proposalData) => {
    try {
        await axios.post(`${API_URL}/project-detail?id=${proposalData.subProjectId}`, proposalData);
        console.log('post proposal to backend:', proposalData);
        // Optionally, handle success cases
    } catch (error) {
        throw error;
    }
};

//File
export const uploadFile = async (subProjectId, partnerEmail, formData) => {
    console.log("subProjectId in front API:",subProjectId);
    if (!subProjectId || !formData) {
        throw new Error("Project ID or form data is missing");
    }

    try {
        const response = await axios.post(
            `${API_URL}/project-detail/upload-file`, 
            formData, 
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                params: { subProjectId, partnerEmail } // Include both parameters
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error uploading plan:", error);
        throw error;
    }
};
