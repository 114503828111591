// my-app/src/api/partnerDashboardApi.js

import axios from 'axios';

import { API_URL } from './constants';

export const fetchSubProjectData = async () => {
    try {
        const response = await axios.get(`${API_URL}/jury`);
        console.log('Fetched jury needed subProjects from the backend:', response.data[0])
        return response.data[0];
    } catch (error) {
        throw error;
    }
};
